export const educationData = [
    {
        id: 1,
        institution: "WBS Coding School, Berlin",
        course: "Full Stack Web & App Development Bootcamp",
        startYear: "07/2022",
        endYear: "11/2022",
    },
    {
        id: 2,
        institution: "KREISVOLKSHOCHSCHULE  WESERMARSCH GMBH, Brake, Deutschland",
        course: "Integrations kurs B1",
        startYear: "09/2021 ",
        endYear: "06/2022",
    },
    {
        id: 3,
        institution: "BHAGWAN MAHAVIR COLLEGE VON POLYTECHNIKUM, Surat, Indien",
        course: "Computer Engineering",
        startYear: "06/2010",
        endYear: "06/2013",
    },
];
