import Priyanka from "../assets/jpg/Priyanka.png";
import Priyanka_Bed_Lebenslauf from "../assets/pdf1/Priyanka_Bed_Lebenslauf.pdf";
import Priyanka_Bed_CV from "../assets/pdf/Priyanka_Bed_CV.pdf";

export const headerData = {
    name: "Priyanka",
    surname: "Bed",
    title: "Full Stack Web-Entwicklerin",
    //desciption: "There wasn't a bird in the sky, but that was not what caught her attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. She knew what was coming and she hoped she was prepared. ",
    image: "https://raw.githubusercontent.com/PhantomScript/asset-container/main/developer-portfolio/landingImg.png",
    image: Priyanka,
    resumePdf: Priyanka_Bed_Lebenslauf,
    resumePdf: Priyanka_Bed_CV,
};
