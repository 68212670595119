import React, { useContext, useState, useRef } from "react";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import axios from 'axios';
// import isEmail from 'validator/lib/isEmail';
import { makeStyles } from "@material-ui/core/styles";
import { FaLinkedinIn, FaXing, FaEnvelope } from "react-icons/fa";
import { AiOutlineSend, AiOutlineCheckCircle } from "react-icons/ai";
import { FiAtSign } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";

import { ThemeContext } from "../../contexts/ThemeContext";

import { socialsData } from "../../data/socialsData";
import { contactsData } from "../../data/contactsData";
import "./Contacts.css";
import emailjs from "@emailjs/browser";

function Contacts() {
    const form = useRef();

    const [open, setOpen] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [success, setSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const { theme } = useContext(ThemeContext);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    // npm i @emailjs/browser

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(`${process.env.REACT_APP_SERVICE_ID}`, `${process.env.REACT_APP_TEMPLATE_ID}`, form.current, `${process.env.REACT_APP_PUBLIC_API}`).then(
            (result) => {
                console.log(result.text);
                console.log("message sent");
            },
            (error) => {
                console.log(error.text);
            }
        );
    };

    // ------------------

    const useStyles = makeStyles((t) => ({
        input: {
            border: `4px solid ${theme.primary80}`,
            backgroundColor: `${theme.secondary}`,
            color: `${theme.tertiary}`,
            fontFamily: "var(--primaryFont)",
            fontWeight: 500,
            transition: "border 0.2s ease-in-out",
            "&:focus": {
                border: `4px solid ${theme.primary600}`,
            },
        },
        message: {
            border: `4px solid ${theme.primary80}`,
            backgroundColor: `${theme.secondary}`,
            color: `${theme.tertiary}`,
            fontFamily: "var(--primaryFont)",
            fontWeight: 500,
            transition: "border 0.2s ease-in-out",
            "&:focus": {
                border: `4px solid ${theme.primary600}`,
            },
        },
        label: {
            backgroundColor: `${theme.secondary}`,
            color: `${theme.primary}`,
            fontFamily: "var(--primaryFont)",
            fontWeight: 600,
            fontSize: "0.9rem",
            padding: "0 5px",
            transform: "translate(25px,50%)",
            display: "inline-flex",
        },
        socialIcon: {
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "21px",
            backgroundColor: theme.primary,
            color: theme.secondary,
            transition: "250ms ease-in-out",
            "&:hover": {
                transform: "scale(1.1)",
                color: theme.secondary,
                backgroundColor: theme.tertiary,
            },
        },
        detailsIcon: {
            backgroundColor: theme.primary,
            color: theme.secondary,
            borderRadius: "50%",
            width: "45px",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "23px",
            transition: "250ms ease-in-out",
            flexShrink: 0,
            "&:hover": {
                transform: "scale(1.1)",
                color: theme.secondary,
                backgroundColor: theme.tertiary,
            },
        },
        submitBtn: {
            backgroundColor: theme.primary,
            color: theme.secondary,
            transition: "250ms ease-in-out",
            "&:hover": {
                transform: "scale(1.08)",
                color: theme.secondary,
                backgroundColor: theme.tertiary,
            },
        },
    }));

    const classes = useStyles();

    // const handleContactForm = (e) => {
    //     e.preventDefault();

    //     if (name && email && message) {
    //         if (isEmail(email)) {
    //             const responseData = {
    //                 name: name,
    //                 email: email,
    //                 message: message,
    //             };

    //             axios.post(contactsData.sheetAPI, responseData).then((res) => {
    //                 console.log('success');
    //                 setSuccess(true);
    //                 setErrMsg('');

    //                 setName('');
    //                 setEmail('');
    //                 setMessage('');
    //                 setOpen(false);
    //             });
    //         } else {
    //             setErrMsg('Invalid email');
    //             setOpen(true);
    //         }
    //     } else {
    //         setErrMsg('Enter all the fields');
    //         setOpen(true);
    //     }
    // };

    return (
        <div className='contacts' id='contacts' style={{ backgroundColor: theme.secondary }}>
            <div className='contacts--container'>
                <h1 style={{ color: theme.primary }}>Kontakt</h1>
                <div className='contacts-body'>
                    <div className='contacts-form'>
                        <form action='' ref={form} onSubmit={sendEmail}>
                            <div className='input-container'>
                                <label htmlFor='Name' className={classes.label}>
                                    Name
                                </label>
                                <input type='text' name='name' placeholder='Name' className={`form-input ${classes.input}`} required />
                            </div>
                            <div className='input-container'>
                                <label htmlFor='Email' className={classes.label}>
                                    E-Mail-Adresse
                                </label>
                                <input type='email' name='email' placeholder='Email' className={`form-input ${classes.input}`} required />
                            </div>
                            <div className='input-container'>
                                <label htmlFor='Message' className={classes.label}>
                                    Nachricht
                                </label>
                                <textarea name='message' className={`form-message ${classes.message}`} placeholder='Ihre Nachricht .....' />
                            </div>
                            <div className='submit-btn'>
                                <button type='submit' className={classes.submitBtn}>
                                    <p>{!success ? "Absenden" : "Sent"}</p>
                                    <div className='submit-icon'>
                                        <AiOutlineSend
                                            className='send-icon'
                                            style={{
                                                animation: !success ? "initial" : "fly 0.8s linear both",
                                                position: success ? "absolute" : "initial",
                                            }}
                                        />
                                        <AiOutlineCheckCircle
                                            className='success-icon'
                                            style={{
                                                display: !success ? "none" : "inline-flex",
                                                opacity: !success ? "0" : "1",
                                            }}
                                        />
                                    </div>
                                </button>
                            </div>
                        </form>
                        {/* <form onSubmit={handleContactForm}>
                            <div className='input-container'>
                                <label htmlFor='Name' className={classes.label}>
                                    Name
                                </label>
                                <input
                                    placeholder='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type='text'
                                    name='Name'
                                    className={`form-input ${classes.input}`}
                                />
                            </div>
                            <div className='input-container'>
                                <label
                                    htmlFor='Email'
                                    className={classes.label}
                                >
                                    Email
                                </label>
                                <input
                                    placeholder='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type='email'
                                    name='Email'
                                    className={`form-input ${classes.input}`}
                                />
                            </div>
                            <div className='input-container'>
                                <label
                                    htmlFor='Message'
                                    className={classes.label}
                                >
                                    Nachricht
                                </label>
                                <textarea
                                    placeholder='Ihre Nachricht .....'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    type='text'
                                    name='Message'
                                    className={`form-message ${classes.message}`}
                                />
                            </div>

                            <div className='submit-btn'>
                                <button
                                    type='submit'
                                    className={classes.submitBtn}
                                >
                                    <p>{!success ? 'Send' : 'Sent'}</p>
                                    <div className='submit-icon'>
                                        <AiOutlineSend
                                            className='send-icon'
                                            style={{
                                                animation: !success
                                                    ? 'initial'
                                                    : 'fly 0.8s linear both',
                                                position: success
                                                    ? 'absolute'
                                                    : 'initial',
                                            }}
                                        />
                                        <AiOutlineCheckCircle
                                            className='success-icon'
                                            style={{
                                                display: !success
                                                    ? 'none'
                                                    : 'inline-flex',
                                                opacity: !success ? '0' : '1',
                                            }}
                                        />
                                    </div>
                                </button>
                            </div>
                        </form> */}
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            open={open}
                            autoHideDuration={4000}
                            onClose={handleClose}
                        >
                            <SnackbarContent
                                action={
                                    <React.Fragment>
                                        <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </React.Fragment>
                                }
                                style={{
                                    backgroundColor: theme.primary,
                                    color: theme.secondary,
                                    fontFamily: "var(--primaryFont)",
                                }}
                                message={errMsg}
                            />
                        </Snackbar>
                    </div>

                    <div className='contacts-details'>
                        <a href={`mailto:${contactsData.email}`} className='personal-details'>
                            <div className={classes.detailsIcon}>
                                <FiAtSign />
                            </div>
                            <p style={{ color: theme.tertiary }}>{contactsData.email}</p>
                        </a>
                        {/* <a
                            href={`tel:${contactsData.phone}`}
                            className='personal-details'
                        >
                            <div className={classes.detailsIcon}>
                                <FiPhone />
                            </div>
                            <p style={{ color: theme.tertiary }}>
                                {contactsData.phone}
                            </p>
                        </a> */}
                        <div className='personal-details'>
                            <div className={classes.detailsIcon}>
                                <HiOutlineLocationMarker />
                            </div>
                            <p style={{ color: theme.tertiary }}>{contactsData.address}</p>
                        </div>

                        <div className='socialmedia-icons'>
                            {socialsData.xing && (
                                <a href={socialsData.xing} target='_blank' rel='noreferrer' className={classes.socialIcon}>
                                    <FaXing aria-label='GitHub' />
                                </a>
                            )}
                            {socialsData.linkedIn && (
                                <a href={socialsData.linkedIn} target='_blank' rel='noreferrer' className={classes.socialIcon}>
                                    <FaLinkedinIn aria-label='LinkedIn' />
                                </a>
                            )}
                            {socialsData.email && (
                                <a href={socialsData.email} target='_blank' rel='noreferrer' className={classes.socialIcon}>
                                    <FaEnvelope aria-label='Email' />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <img src={theme.contactsimg} alt='contacts' className='contacts--img' />
        </div>
    );
}

export default Contacts;
