import one from "../assets/svg/projects/Kidify.png";
import two from "../assets/svg/projects/to_do_list_java.png";
import three from "../assets/svg/projects/hackerNews.png";

export const projectsData = [
    {
        id: 1,
        projectName: "Kidify Website for Kids ",
        projectDesc: "Unsere Vision ist es, dass die Website die Quelle für kindliches Lernen im Internet ist.",
        tags: ["React", "Node.js", "CSS", "Bootstrap", "MongoDB", "Express"],
        code: "https://github.com/PriyankaBed/Final_Project_Kidify",
        demo: "https://kidify-project.onrender.com/",
        image: one,
    },
    {
        id: 2,
        projectName: "To-Do List",
        projectDesc: "Es ist eine Liste von Aufgaben, die Sie erledigen müssen oder Dinge, die Sie tun möchten.",
        tags: ["Html", "CSS", "Java Script"],
        code: "https://github.com/PriyankaBed/To_do_List_javascript",
        demo: "https://to-do-list-javascript-priyanka.netlify.app/",
        image: two,
    },
    {
        id: 3,
        projectName: "Hacker News",
        projectDesc: " Projekt zum Sammeln von Nachrichtenartikeln mit einer Suchfunktion von Hacker News mit Hilfe der Restful API.",
        tags: ["React", "CSS", "React pagination"],
        code: "https://github.com/PriyankaBed/hacker-news",
        demo: "https://fluffy-lolly-667a9a.netlify.app",
        image: three,
    },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
