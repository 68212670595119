export const experienceData = [
    {
        id: 1,
        company: "LOGITECH SOLUTION, Surat, Indien",
        jobtitle: "Senior SEO executive",
        startYear: "2019",
        endYear: "2021",
    },
    {
        id: 2,
        company: "GLAM ELEGANCE, Surat, Indien",
        jobtitle: "Digital Marketing(B2B, B2C) maintain IT system & software",
        startYear: "2015",
        endYear: "2019",
    },
];
